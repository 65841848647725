<template>
    <div v-if="isMobile" class="flex flex-col relative top-[70px]">
      <div class="flex flex-row justify-evenly w-full shadow-lg fixed z-20 bg-gray-50">
        <div
          class="w-50% pt-2 flex flex-col"
          v-for="(option, index) in options"
          :key="index"
          @click="switchTab(index)"
        >
          <div class="flex flex-col items-center justify-start p-2" :class="index == tab ? 'border-b-4 border-primary-600' : 'border-b-4 border-gray-200'">
            <div class="flex items-center justify-around text-gray-700 p-1 uppercase font-bold">
              {{ option.title }}
            </div>
          </div>
        </div>
      </div>
  
      <div v-if="settings" class="flex flex-col justify-between p-5 bg-gray-50 relative z-10 top-[60px]">
        <div v-for="(setting, index) in settings" :key="index" class="border-b mb-5">
          <div class="flex flex-row justify-between items-center">
            <h3 class="text-title-3">
              {{ setting.identifier.replace('_', ' ').toUpperCase() }}
            </h3>
            <ButtonVue :label="null" :filling="'filled'" :height="'large'" :state="'default'" :callbackState="callbackState[index]" :icon="'ArrowDownTrayIcon'" @click="save(setting, index)" />
          </div>
          <div class="flex flex-row flex-wrap w-full items-end justify-between">
            <div class="my-2" :class="settings[0].type == 'Linear' ? 'w-50%' : 'w-full'" v-for="(display, indexDisplay) in setting.display" :key="indexDisplay">
              <component class="flex-grow" :class="settings[0].type == 'Linear' ? 'm-2' : 'my-2'" :is="display.field" v-bind="display" v-model="setting.value[indexDisplay].value" />
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div v-else class="flex flex-row justify-between relative top-[100px]">
      <div class="fixed top-[100px] flex flex-col justify-between h-[calc(100vh-100px)] w-[86px] lg:w-[250px] bg-gray-100 shadow-lg">
        <div class="flex flex-col mt-2">
          <div class="px-4 py-2 flex flex-col" v-for="(option, index) in options" :key="index" @click="switchTab(index)">
            <div class="flex flex-row items-center justify-start rounded-md p-2" :class="index == tab ? 'bg-gray-200' : null">
              <div class="border border-gray-500 flex items-center justify-around rounded-md text-gray-500 shadow-xs p-1">
                <component :is="heroIcons[option.icon]" fill="none" class="stroke-1 h-7 w-7" />
              </div>
              <span class="ml-2 text-body-2 text-gray-500 hidden lg:block">{{ option.title }}</span>
            </div>
          </div>
        </div>
        <ButtonVue class="!m-4 overflow-hidden" :label="null" :filling="'filled'" :height="'large'" :state="'danger'" :icon="'ArrowRightOnRectangleIcon'" @click="logout()" />
      </div>
  
      <div v-if="settings" class="absolute left-[86px] lg:left-[250px] w-[calc(100vw-86px)] lg:w-[calc(100vw-250px)] flex flex-col justify-between p-5">
        <div v-for="(setting, index) in settings" :key="index" class="border-b mb-5">
          <div class="flex flex-row justify-between items-center">
            <h3 class="text-title-3">
              {{ setting.identifier.replaceAll('_', ' ').toUpperCase() }}
            </h3>
            <ButtonVue :label="null" :filling="'filled'" :height="'large'" :state="'default'" :callbackState="callbackState[index]" :icon="'ArrowDownTrayIcon'" @click="save(setting, index)" />
          </div>
          <div class="flex flex-row flex-wrap justify-start">
            <div class="w-full m-4" v-for="(display, indexDisplay) in setting.display" :key="indexDisplay">
              <component :is="display.field" v-bind="display" v-model="setting.value[indexDisplay].value" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  

  <script>
  import * as heroIcons from '@heroicons/vue/24/outline';
  import ButtonVue from '@/components/elements/Button.vue';
  import InputVue from '@/components/elements/Input.vue';
  import CheckboxVue from '@/components/elements/Checkbox.vue';
  import OptionTableVue from '@/components/elements/OptionTable.vue';
  import ColumnTableVue from '@/components/elements/ColumnTable.vue';
  //import WebsocketService from '@/utils/WebsocketService';
  import Setting from '@/utils/entities/Setting'
  
  export default {
    name: 'SettingsView',
    components: {
      ButtonVue,
      InputVue,
      CheckboxVue,
      OptionTableVue,
      ColumnTableVue,
    },
    data() {
      return {
        reloaded: 0,
        heroIcons: heroIcons,
        settings: [],
        tab: 0,
        modalOpen: true,
        mapUpdate: [],
        subscribedSettings: new Set(),
        callbackState: [],
        options: [
          {
            title: "Shelves",
            icon: "Square3Stack3DIcon",
            url: process.env.VUE_APP_API_BASE_PATH + "/settings?type=Linear",
          },
          {
            title: "Rayons",
            icon: "RectangleGroupIcon",
            url: process.env.VUE_APP_API_BASE_PATH + "/settings?type=Sections",
          },
          {
            title: "Products",
            icon: "CubeIcon",
            url: process.env.VUE_APP_API_BASE_PATH + "/settings?type=ProductColumns",
          },
          {
            title: "Alertes",
            icon: "BellIcon",
            url: process.env.VUE_APP_API_BASE_PATH + "/settings?type=Alerts",
          },
          {
            title: "Evenements",
            icon: "CalendarIcon",
            url: process.env.VUE_APP_API_BASE_PATH + "/settings?type=Event",
          },
        ],
      };
    },
    computed: {
        isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
    async mounted() {
      //WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
      await this.getSettings().then(() => {
        this.callbackState = []
        this.settings.forEach((setting,index) => this.callbackState[index] = 'default')
          //WebsocketService.addListener((type, data) => {
          //  if (type === 'message') {
          //    this.handleWebSocketMessage(data);
          //  }
          //});
//
          //WebsocketService.addListener((type) => {
          //  if (type === 'open') {
          //    this.settings.forEach(setting => setting.initializeWebSocketListener())
          //    WebsocketService.sendMessage({
          //      command: 'subscribe',
          //      resource: '/api/settings',
          //    });
          //  }
          //});
        }
      );
    },
    unmounted() {
      //this.settings.forEach(setting => {
      //  setting.unsubscribeWebSocket()
      //})
    },
    //watch:{
    //  settings:{
    //    handler(newSettings, oldSettings) {
    //      oldSettings.forEach((setting) => {
    //        if (!newSettings.includes(setting)) {
    //          setting.unsubscribeWebSocket();
    //          this.subscribedSettings.delete(setting); // Remove from the Set
    //        }
    //      });
//
    //      newSettings.forEach((setting) => {
    //        if (!this.subscribedSettings.has(setting)) {
    //          setting.initializeWebSocketListener();
    //          this.subscribedSettings.add(setting); // Add to the Set to track subscription
    //        }
    //      });
    //    },
    //    deep: true,
    //  }
    //},
    methods: {
      //handleWebSocketMessage(data) {
      //  if (data.command === 'update' && (new Date().getTime()) - this.reloaded > 2000) {
      //    this.getSettings();
      //  }
      //},
      switchTab(n) {
        this.tab = n;
        this.getSettings();
      },
      async getSettings() {
        try {
          const response = await this.$axios.get(this.options[this.tab].url, { withCredentials: true });
          this.settings = [];
          response.data['hydra:member'].forEach(setting => this.settings.push(new Setting(setting)))
        } catch (error) {
          console.error('Error fetching settings:', error);
        }
      },
      async save(setting, index) {
        this.callbackState[index] = 'loading';
        try {
          if (setting.type === 'Sections') {
            await this.updateMap(setting);
          }
          this.reloaded = new Date().getTime();
          const response = await this.$axios.patch(
            `${process.env.VUE_APP_API_BASE_PATH}/settings/${setting.identifier}`,
            { value: setting.value },
            { withCredentials: true, headers: { 'Content-Type': 'application/merge-patch+json' } }
          );
          this.callbackState[index] = 'success';
          this.setting = response.data;
        } catch (error) {
          console.error('Error saving settings:', error);
          this.callbackState[index] = 'error';
        }
      },
      async logout() {
        try {
          const response = await this.$axios.post(`${process.env.VUE_APP_API_BASE_PATH}/logout`, {}, { withCredentials: true });
          if (response.status === 200) {
            window.location.replace('/login');
          }
        } catch (error) {
          console.error('Error during logout:', error);
        }
      },
      async updateMap(setting) {
        try {
          const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_PATH}/settings/map_display`, { withCredentials: true });
          this.mapUpdate = response.data.value;
          const legend = setting.value[0].value;
  
          this.mapUpdate.map_content.forEach((element) => {
            const sectionExists = legend.some((legendElement) => legendElement.id === element.section_id);
            if (!sectionExists) {
              element.fill = '#808080';
              element.section_id = null;
              element.section = null;
            }
          });
  
          await this.$axios.patch(
            `${process.env.VUE_APP_API_BASE_PATH}/settings/map_display`,
            { value: { map_size: this.mapUpdate.map_size, map_content: this.mapUpdate.map_content } },
            { withCredentials: true, headers: { 'Content-Type': 'application/merge-patch+json' } }
          );
        } catch (error) {
          console.error('Error updating map display settings:', error);
        }
      },
    },
  };
  </script>
  