import axios from 'axios'
import Module from "./Module"
import Event from "./Event"
import WebsocketService from '../WebsocketService'
import ChartColors from '../ChartColors'
import { DateTime } from 'luxon';
import 'chartjs-adapter-luxon';

export default class Product { 

    constructor(object){
        this.build(object)        
    }

    initializeWebSocketListener() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

        WebsocketService.sendMessage({
            command: 'subscribe',
            resource: `/api/products/${this.barCode}`,
        });

        WebsocketService.addListener(async (type, data) => {
            if (type === 'message' && data.resource === `/api/products/${this.barCode}`) {
                await this.retrieveEntity();
            }
        });
    }
    
    unsubscribeWebSocket() {
        WebsocketService.sendMessage({
            command: 'unsubscribe',
            resource: `/api/products/${this.barCode}`,
        });
    }

    build(object){
        object.id ? this.id = object.id : this.id = null
        object.barCode ? this.barCode = object.barCode : this.barCode = null
        object.brand ? this.brand = object.brand : this.brand = null
        object.counts ? this.counts = object.counts : this.counts = [];
        object.totalCount !== undefined ? this.totalCount = object.totalCount : this.totalCount = 'N/A';
        object.imageUrl ? this.imageUrl = object.imageUrl : this.imageUrl = null;
        object.minProduct !== undefined ? this.minProduct = object.minProduct : this.minProduct = 'N/A';
        if(object.modules){
            var modules = []
            object.modules.forEach((module) => {
                modules.push(new Module(module))
            })
            this.modules = modules
        }
        object.netWeight ? this.netWeight = object.netWeight : this.netWeight = 0
        object.reference ? this.reference = object.reference : this.reference = null
        object.weight ? this.weight = object.weight : this.weight = 0
        object.section ? this.section = object.section : this.section = -1
        object.isSensitive ? this.isSensitive = object.isSensitive : this.isSensitive = false
        object.expiringOffset ? this.expiringOffset = object.expiringOffset : this.expiringOffset = 60
        object.refEvents ? this.refEvents = object.refEvents : this.refEvents = []
        object.compEvents ? this.compEvents = object.compEvents : this.compEvents = []
        object.events ? this.events = object.events : this.events = {
            datasets: [
                {
                    label: '',
                    backgroundColor: ChartColors[500].hex,
                    borderColor: ChartColors[500].hex,
                    data: [],
                },
                {
                    label: '',
                    backgroundColor: ChartColors[0].hex,
                    borderColor: ChartColors[0].hex,
                    data: [],
                }
            ]
        }

        
    }

    serialize(){
        return {
            brand: this.brand,
            imageUrl: this.imageUrl,
            netWeight: this.netWeight,
            reference: this.reference,
            weight: this.weight,
            minProduct: this.minProduct,
            isSensitive : this.isSensitive,
            expiringOffset : parseInt(this.expiringOffset)
        }
    }

    async retrieveEntity() {
        if(this.barCode){
            await axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/products/'+this.barCode,
            {
                withCredentials: true
            })
            .then(response => {
                if(response.status == 200){
                    this.build(response.data)
                }
            })
        }
    }
    async getEvents(type, parameters) {

        
        let url = `${process.env.VUE_APP_API_BASE_PATH}/events?page=${parameters.page || 1}`;
        if (parameters.barCode !== undefined) {
            url += `&product.barCode=${parameters.barCode}`;
        } else {
            url += `&product.barCode=${this.barCode}`;
        }
        const currentDate = new Date();
        if(parameters.dateEnd instanceof Date && !isNaN(parameters.dateEnd)){
            url += `&createdAt[before]=${parameters.dateEnd.toISOString()}`;
        } else {
            url += `&createdAt[before]=${currentDate.toISOString()}`;
        }
        
        const sevenDaysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));
        if(parameters.dateStart instanceof Date && !isNaN(parameters.dateStart)){
            url += `&createdAt[after]=${parameters.dateStart.toISOString()}`;
        } else {
            url += `&createdAt[after]=${sevenDaysAgo.toISOString()}`;
        }
       
        url += `&order[createdAt]=ASC`;
  
        try {
          await axios.get(url, 
            {
                withCredentials: true , 
                timeout: 5000
            }
        ).then((response) => {
            if (response.status === 200) {
                const targetEvents = type === 'Reference' ? this.refEvents : this.compEvents;
                const index = type === 'Reference' ? 0 : 1;
                targetEvents.length = 0;
                
                response.data['hydra:member'].forEach(event => {
                    targetEvents.push(new Event(event));
                });
                this.events.datasets[index].label = type + ' Events'
                this.events.datasets[index].data = [];
                targetEvents.forEach(event => {
                    this.events.datasets[index].data.push({
                        x: DateTime.fromISO(event.createdAt).toISO(),
                        y: event.totalAfterGlobal || 0,
                    });
                });
                if(this.events.datasets[index].data.length){
                    this.events.datasets[index].data.push({
                        x: DateTime.fromISO((new Date()).toISOString()).toISO(),
                        y: this.events.datasets[index].data[this.events.datasets[index].data.length - 1].y,
                    });
                    const date1 = new Date(this.events.datasets[index].data[0].x);
                    const date2 = parameters.dateStart;
                    const differenceInMilliseconds = date2 - date1;
                    const differenceInHours = Math.abs(differenceInMilliseconds) / (1000 * 60 * 60);
                    if (differenceInHours >= 2) {
                        this.events.datasets[index].data.unshift({
                            x: DateTime.fromISO((new Date(date1.setHours(currentDate.getHours() - 1))).toISOString()).toISO(),
                            y: targetEvents[0].totalAfterGlobal - targetEvents[0].differenceProduct,
                        });
                        this.events.datasets[index].data.unshift({
                            x: DateTime.fromISO(parameters.dateStart.toISOString()).toISO(),
                            y: targetEvents[0].totalAfterGlobal - targetEvents[0].differenceProduct,
                        });
                    } 
                }
                this.loaded = true;
              }
          });
        } catch (error) {
          console.error('Error fetching events:', error);
          const targetEvents = type === 'Reference' ? this.refEvents : this.compEvents;
          targetEvents.length = 0;
        }
    }

    async getEventsExtract(filtersIndex) {
        let getParameters = '?'
        Object.keys(this.filters[filtersIndex]).forEach((element,index) => {
            if(element !== 'barCode'){
                if(this.filters[filtersIndex][element] instanceof Date && !isNaN(this.filters[filtersIndex][element].valueOf())){
                    getParameters += element+"="+this.filters[filtersIndex][element].toISOString()
                } else {
                    getParameters += element+"="+this.filters[filtersIndex][element]
                }
                if(index != Object.keys(this.filters[filtersIndex]).length - 1){
                    getParameters += "&"
                }
            }
        })
        await axios
        .get(process.env.VUE_APP_API_BASE_PATH+'/events_extract'+getParameters,
        {
            withCredentials: true
        })
        .then(response => {
            if(response.status == 201){
                const link = document.createElement('a')
                let path = ""
                let basePathArray = process.env.VUE_APP_API_BASE_PATH.split('/api')
                if(basePathArray.length > 2){
                    let i = 0
                    for(i; i < basePathArray.length - 2; i++){
                        path += basePathArray[i]+'/api'
                    }
                    path += basePathArray[i]
                } else {
                    path = basePathArray[0]
                }

                console.log(path)
                link.href = path+response.data.fileUri
                link.target = "_blank"
                link.click()
                URL.revokeObjectURL(link.href)
            }
        })
    }


    getLocations(){
        var locations = []
        this.modules.forEach(module => {
            var index = locations.find(element => element === module.parentLinear.getLocation())
            if(!index){
                locations.push({
                    linear: module.parentLinear.getLocation(),
                    modules: [module.moduleOrder]
                })
            } else {
                locations[index].modules.push(module.moduleOrder)
            }
        })
        return locations
    }
    
    getLocationsForDisplay(){
        var displayLocations = []
        var locations = this.getLocations()
        locations.forEach(location => {
            displayLocations.push(
                'Allée :'+ location.linear.aisle +' - Rang :'+ location.linear.position +' - Étage :'+ location.linear.storey +' - Section :'+ location.modules.toString()
            )
        })
        return displayLocations
    }

    getTotalCount(){
        var total = 0;
        this.modules.forEach(module => {
            total += module.productCount
        })
        return total
    }

    async associateProduct(modules){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/products/'+this.barCode+'/associate', 
            modules,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            this.build(response.data)
        })
    }

    async updateProduct(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/products/'+this.barCode, 
            {
                weight: parseInt(this.weight),
                netWeight: parseInt(this.netWeight),
                reference: this.reference,
                brand: this.brand,
                imageUrl: this.imageUrl,
                section: parseInt(this.section) !== -1 ? this.section : "",
                minProduct: parseInt(this.minProduct),
                isSensitive : this.isSensitive,
                expiringOffset : parseInt(this.expiringOffset)
            },
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            this.build(response.data)
        })
    }

    async createProduct(){
        await axios
        .post(process.env.VUE_APP_API_BASE_PATH+'/products', 
            {
                barCode: this.barCode,
                weight: parseInt(this.weight),
                netWeight: parseInt(this.netWeight),
                reference: this.reference,
                brand: this.brand,
                imageUrl: this.imageUrl,
                section: parseInt(this.section) !== -1 ? this.section : "",
                minProduct: parseInt(this.minProduct),
                isSensitive : this.isSensitive,
                expiringOffset : parseInt(this.expiringOffset)
            },
            {
                withCredentials: true,
            })
        .then(response => {
            this.build(response.data)
            console.log('creation du produit');
        })
    }
}


/*
http://127.0.0.1:8084/api/events?page=1&product.barCode=3068320124537&createdAt[before]=2024-10-02T12:32:56.593Z&createdAt[after]=2024-09-25T12:32:56.593Z&order[createdAt]=ASC
http://127.0.0.1:8084/api/events?page=1&product.barCode=3068320124537&createdAt[before]=2024-10-09T12:32:56.593Z&createdAt[after]=2024-10-02T12:32:56.593Z&order[createdAt]=ASC
http://127.0.0.1:8084/api/events?page=1&product.barCode=3068320124537&createdAt[before]=2024-10-02T12:32:56.593Z&createdAt[after]=2024-09-25T12:32:56.593Z&order[createdAt]=ASC
http://127.0.0.1:8084/api/events?page=1&product.barCode=3068320124537&createdAt[before]=2024-10-09T12:32:56.593Z&createdAt[after]=2024-10-02T12:32:56.593Z&order[createdAt]=ASC
*/