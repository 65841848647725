<template>
    <div class="relative overflow-x-auto sm:rounded-lg container shadow-lg mx-auto w-full" :class="isMobile ? 'top-[70px]' : 'top-[100px]'">
      <LoadingVue v-if="!loaded" />
      <div v-else>
        <!-- Mobile View -->
        <div v-if="isMobile" class="flex flex-col items-center h-[100vh] pb-32 overflow-y-auto overscroll-contain">
          <!-- Mobile Top Controls -->
          <div class="flex flex-row py-1 w-full fixed bg-white items-center justify-center z-40">
            <PaginatorVue class="m-1 select-none" 
              :pageNumber="parameters.page" 
              :pageTotal="pageTotal"               
              @nextPage="nextPage()" 
              @previousPage="previousPage()" 
            />
            <router-link to="/event_create">
              <ButtonVue class="mx-1" :filling="'filled'" :icon="'PlusIcon'" :height="'medium'" :state="'default'" />
            </router-link>
            <ButtonVue class="mx-1" @click="modalOpen = true" :filling="'filled'" :icon="'FunnelIcon'" :height="'medium'" :state="'default'" />
            <ButtonVue class="mx-1" @click="log('click')" :filling="'filled'" :icon="'ClipboardDocumentCheckIcon'" :height="'medium'" :state="'default'" />
          </div>
  
          <!-- Event Table for Mobile -->
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 relative top-[55px]">
            <tbody>
              <tr v-for="event in events" :key="event.id" class="bg-white border-b hover:bg-gray-50">
                <td class="px-2 py-4 font-medium text-gray-900">

                  <div class="flex flex-row pb-3 items-start">
                    <ButtonVue class="mx-1 inline-block" :filling="'outlined'" :label="eventLabel(event)" :height="'medium'" :state="calculateButtonState(event.totalCount, event.minProduct)" />
                    <div class="inline-block flex-wrap flex-grow mx-2">
                      <div class="wrap-text">{{ event.product.brand }}</div>
                      <div class="wrap-text font-light">{{ event.product.reference }}</div>
                    </div>
                    <div class="flex flex-row">
                      <router-link :to="'/products/' + event.product.barCode">
                        <ButtonVue v-if="!startedSelection" class="mx-1 inline-block" :filling="'filled'" :height="'medium'" :icon="'PencilIcon'" :color="'primary'" />
                      </router-link>
                      <ButtonVue class="mx-1 inline-block" @click="handleOpenevent(event.product.barCode)" :filling="'outlined'" :height="'medium'" :icon="'ChevronDownIcon'" :state="'neutral'" />
                    </div>
                  </div>
  
                  <!-- Additional Info -->
                  <div v-if="additionalInfoVisible[event.barCode]" class="pt-4 border-t border-gray-300">
                    <div v-for="(setting, index) in filteredSettings" :key="index">
                      <div v-if="setting.column === 'barCode'">Code Barre : {{ event.product.barCode }}</div>
                      <div v-if="setting.column === 'section'">Rayon : {{ event.product.section }}</div>
                      <div v-if="setting.column === 'weight'">Poids : {{ event.product.weight }}g</div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
  
          <!-- Filter Modal -->
          <BottomSheetVue v-if="modalOpen" @closeModal="modalOpen = false">
            <template v-slot:body>
              <div class="flex flex-col items-center gap-y-2 py-5">
                <div class="flex flex-row">
                  <ButtonVue class="mx-1" @click="resetFilters" :filling="'outlined'" :icon="'XMarkIcon'" :height="'medium'" :state="'danger'" />
                </div>
                <AutocompleteVue class="mx-1 w-[80%]" v-model="parameters.brandAndRef" :height="'medium'" :placeholder="'Marque, Réf'" @update:modelValue="getEvents" />
                <SelectVue class="mx-1 w-[80%]" v-model="parameters.section" :options="sections" :placeholder="'Rayon'" :height="'medium'" :state="'default'" @update:modelValue="getEvents" />
              </div>
            </template>
          </BottomSheetVue>
        </div>
  
        <!-- Desktop View -->
        <div v-else class="h-[calc(100vh-100px)]">
            <!-- Desktop Top Controls -->
            <div class="flex flex-row py-2 px-6 justify-between items-center flex-wrap w-full">
                <PaginatorVue
                class="m-1 select-none" 
                :pageNumber="parameters.page" 
                :pageTotal="pageTotal"
                @nextPage="nextPage()" 
                @previousPage="previousPage()" 
                />
                <!-- Filters and Controls -->
                <div class="flex flex-row w-[50%] min-w-[400px] mr-4 justify-end">
                  <div class="relative mx-1">
                    <div @click="parameters.alertTypesOpen = !parameters.alertTypesOpen" class="border-gray-200 flex justify-between items-center pl-4" :class="{ 'bg-gray-200':parameters.alertTypesOpen }">
                      <p class="text-md mx-4">Alertes</p>
                      <ButtonVue :filling="'outlined'" :height="'medium'"  :label="null" :icon="parameters.alertTypesOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                    </div>
                    <div v-show="parameters.alertTypesOpen" class="px-4 py-4 absolute top-[100%] right-0 w-[400px] bg-gray-50 shadow-xl">
                        <div v-for="(alertType, index) in parameters.alertTypes" :key="index" class="flex flex-row items-center justify-between">
                            <CheckboxVue v-model="alertType.isChecked" :label="alertType.title"/>
                            <span class="relative inline-flex rounded-full h-5 w-5" :class="'bg-'+alertType.color"></span>
                        </div>
                        
                        <ButtonVue class="w-full mt-2" :filling="'filled'" :height="'large'" :label="'RECHERCHER'" @click="() => {
                          getEvents()
                          parameters.alertTypesOpen = false
                          }" />

                    </div>
                  </div>
                  <div class="relative mx-1">
                    <div @click="parameters.dateFilterOpen = !parameters.dateFilterOpen" class="border-gray-200 flex justify-between items-center pr-4" :class="{ 'bg-gray-200':parameters.dateFilterOpen }">
                      <ButtonVue :filling="'outlined'" :height="'medium'"  :label="null" :icon="parameters.dateFilterOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                      <p class="text-md mx-4">Période</p>
                    </div>
                    <div v-show="parameters.dateFilterOpen" class="px-4 py-4 absolute top-[100%] left-0 w-[400px] bg-gray-50 shadow-xl">
                        <DatetimefieldVue class="w-full mt-2" v-model="parameters.dateStart" :label="'Depuis'" />
                        <DatetimefieldVue class="w-full mt-2" v-model="parameters.dateEnd" :label="'Jusqu\'à'" />
                        <ButtonVue class="w-full mt-2" :filling="'filled'" :height="'large'" :label="'RECHERCHER'" @click="() => {
                          getEvents()
                          parameters.dateFilterOpen = false
                          }" />

                    </div>
                  </div>
                  <AutocompleteVue 
                      class="mx-1 w-50%" 
                      v-model="parameters.brandAndRef" 
                      :height="'medium'"
                      :placeholder="'Marque Référence'" 
                      @update:modelValue="getEvents" 
                  />
                  <ButtonVue 
                      class="mx-1" 
                      @click="resetFilters" 
                      :filling="'outlined'" 
                      :icon="'XMarkIcon'" 
                      :height="'medium'" 
                      :state="'danger'" 
                  />
                </div>
            </div>

            <!-- Event Table for Desktop -->
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                <tr>
                    <th class="px-8 py-3">ALERTES</th>
                    <th 
                    v-for="(setting, index) in settings.filter(s => s.state)" 
                    :key="index"
                    class="px-6 py-3"
                    >
                      <span class="inline-flex select-none" @click="toggleSettingOrder(setting)">
                        {{ setting.title }}
                        <span :class="setting.changed ? 'text-success-500' : 'text-neutral-500'">
                          <component v-if="setting.order"
                          :is="setting.order == 'desc' ? heroIcons['ChevronDownIcon'] : heroIcons['ChevronUpIcon']"
                          fill="currentColor" 
                          class="stroke-1 h-4 w-4 ml-2"/>
                        </span>
                      </span> 
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr 
                    v-for="event in events" 
                    :key="event.id" 
                    class="bg-white border-b hover:bg-gray-50"
                    >
                    <th>
                      <div class="flex justify-start items-center h-full px-4">
                        <span class="relative flex h-5 w-5 mx-2" v-for="alert in event.alerts" :key="alert.id" @mouseenter="showPopover(alert.id)" @mouseleave="hidePopover(alert.id)">
                          <span class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                            :class="alertStateRing(alert)"></span>
                          <span class="relative inline-flex rounded-full h-5 w-5"
                            :class="alertState(alert)"></span>
                          <div v-if="popoverId === alert.id" class="absolute bottom-5 left-5 bg-gray-700 text-white text-sm p-2 rounded shadow-lg mt-2 z-10 inline-flex whitespace-nowrap">
                            {{ alert.title }}
                          </div>
                        </span>
                      </div>
                    </th>
                    <td 
                    v-for="(setting, index) in settings.filter(s => s.state)" 
                    :key="index"
                    class="px-6 py-4"
                    >
                    {{ getNestedValue(event, setting.column) }}
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import * as heroIconsOutline from '@heroicons/vue/24/outline'
  import LoadingVue from '../components/elements/Loading.vue';
  import ButtonVue from '../components/elements/Button.vue';
  import PaginatorVue from '../components/elements/Paginator.vue';
  import AutocompleteVue from '../components/elements/Autocomplete.vue';
  import SelectVue from '../components/elements/Select.vue';
  import BottomSheetVue from '../components/containers/BottomSheet.vue';
  import WebsocketService from '@/utils/WebsocketService';
  import Event from '@/utils/entities/Event';
  import DatetimefieldVue from '../components/elements/Datetimefield.vue'
  import MultiSelectVue from '../components/elements/MultiSelect.vue'
  import CheckboxVue from '@/components/elements/Checkbox.vue';
  
  export default {
    name: 'EventsView',
    components: {
      LoadingVue,
      PaginatorVue,
      ButtonVue,
      AutocompleteVue,
      SelectVue,
      BottomSheetVue,
      DatetimefieldVue,
      MultiSelectVue,
      CheckboxVue
    },
    data() {
      return {
        heroIcons: heroIconsOutline,
        loaded: false,
        events: [],
        settings: [],
        pageTotal: 1,
        sections: [],
        dateOrderIcon: 'ChevronDownIcon',
        additionalInfoVisible: {},
        startedSelection: false,
        modalOpen: false,
        parameters: {
          page: 1,
          section: -1,
          brandAndRef: '',
          dateStart:null,
          dateEnd:null,
          dateFilterOpen: false,
          alertTypesOpen: false,
          alertTypes: [
            {
              title: 'RUPTURE PARTIELLE',
              color: 'alert-warning',
              type: 'warning',
              isChecked: false,
            },
            {
              title: 'RUPTURE TOTALE',
              color: 'alert-danger',
              type: 'danger',
              isChecked: false,
            },
            {
              title: 'SUSPICION DE VOL',
              color: 'alert-security-high',
              type: 'security-high',
              isChecked: false,
            },
            {
              title: 'MOUVEMENT SUR PRODUIT SENSIBLE',
              color: 'alert-security-low',
              type: 'security-low',
              isChecked: false,
            },
            {
              title: 'AUCUNE ALERTE',
              color: '',
              type: 'none',
              isChecked: false,
            }
          ]
        },
        subscribedEvents: new Set(),
        eventsLoaded: false,
        alreadySubscribed: false,
        popoverId: null,
      };
    },
    computed: {
        filteredSettings() {
            return this.settings.filter((s) => s.state);
        },
        isMobile() {
            return this.$device.isMobile;
        },
        isPortrait() {
            return this.$device.isPortrait;
        },
    },
    async created() {
        try {
            const response = await axios.get(`${process.env.VUE_APP_API_BASE_PATH}/settings/events_columns`, { withCredentials: true });
            this.settings = response.data.value[0].value;
            this.settings.forEach((setting) => {
              if(['createdAt', 'totalAfterGlobal', 'totalAfterByLocation', 'differenceProduct', 'differenceProduct'].includes(setting.column)) {
                setting.order = 'desc'
                setting.changed = false
              }
            })
        } catch (error) {
            console.error('Error loading settings:', error);
        }
    },

    watch:{
      events:{
        handler(newEvents, oldEvents) {
          if(this.alreadySubscribed){
            oldEvents.forEach((event) => {
              if (!newEvents.includes(event)) {
                event.unsubscribeWebSocket();
                this.subscribedEvents.delete(event);
              }
            });
            newEvents.forEach((event) => {
              if (!this.subscribedEvents.has(event)) {
                event.initializeWebSocketListener();
                this.subscribedEvents.add(event);
              }
            });
          }
        },
        deep: true,
      }
    },

    async mounted() {
      this.setupParameters();
      WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

      await this.loadSections();
      await this.getEvents().then(async () => {
        this.eventsLoaded = true;
        await this.waitForWebSocketConnection().then(() => {
          this.checkAndSubscribe();
        });
      });

      WebsocketService.addListener((type, data) => {
        if (type === 'message') {
          this.handleWebSocketMessage(data);
        }
      });
      this.$emit('loaded');
    },

    beforeUnmount() {
      this.events.forEach(event => {
          event.unsubscribeWebSocket()
      })
      WebsocketService.sendMessage({
          command: 'unsubscribe',
          resource: '/api/events',
        });
        this.subscribedEvents=new Set();
    },

    methods: {
      showPopover(alertId) {
        this.popoverId = alertId; // Show the popover for the hovered alert
      },
      hidePopover(alertId) {
        if (this.popoverId === alertId) {
          this.popoverId = null; // Hide the popover when the mouse leaves
        }
      },
      getNestedValue(obj, path) {
        const nestedProperty =  path.split('.').reduce((acc, part) => {
          return acc !== undefined && acc !== null ? (acc[part] || 0) : undefined;
        }, obj);

        if (typeof nestedProperty === 'object' && nestedProperty !== null && 
          'aisle' in nestedProperty && 'storey' in nestedProperty && 'section' in nestedProperty && 'position' in nestedProperty) {
          return `A${nestedProperty.aisle} - R${nestedProperty.storey} - E${nestedProperty.section} - S${nestedProperty.position}`;
        }

        if (nestedProperty && typeof nestedProperty === 'string' && !isNaN(Date.parse(nestedProperty))) {
            const date = new Date(nestedProperty);
            
            const formattedDate = date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });
            return formattedDate;
        }

        //if (nestedProperty && typeof nestedProperty === 'string' && !isNaN(Date.parse(nestedProperty))) {
        //    const date = new Date(nestedProperty);
        //    
        //    // Format the date as desired
        //    const formattedDate = date.toLocaleDateString('en-GB', {
        //        year: 'numeric',
        //        month: '2-digit',
        //        day: '2-digit',
        //        hour: '2-digit',
        //        minute: '2-digit',
        //        second: '2-digit',
        //    });
        //    return formattedDate;
        //}
        return nestedProperty;

      },
      alertState(alert) {
        switch (alert.type) {
          case "warning":
              return "bg-alert-warning"
          case "danger":
              return "bg-alert-danger"
          case "success":
              return "bg-alert-success"
          case "maintenance":
              return "bg-alert-maintenance"
          case "security-high":
              return "bg-alert-security-high"
          case "security-low":
              return "bg-alert-security-low"
        }
      },
      alertStateRing(alert) {
        switch (alert.type) {
            case "warning":
                return "bg-alert-warning"
            case "danger":
                return "bg-alert-danger"
            default:
              return ""
        }
      },
      async waitForWebSocketConnection() {
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            if (WebsocketService.isConnected) {
              clearInterval(interval);
              resolve();
            }
          }, 1000); // Check every 1 second
        });
      },
      checkAndSubscribe() {
        this.events.forEach((event) => {
            if (!this.subscribedEvents.has(event)) {
              event.initializeWebSocketListener();
              this.subscribedEvents.add(event);
            }
          });

        WebsocketService.sendMessage({
          command: 'subscribe',
          resource: '/api/events',
        });
        this.alreadySubscribed = true
      },
      setupParameters() {
        const router = this.$router.currentRoute.value.query;
        this.parameters.page = parseInt(router.page) || 1;
        this.parameters.section = parseInt(router.section) || -1;
        this.parameters.brandAndRef = router.brandAndRef || '';
      },
      async loadSections() {
        try {
          const response = await axios.get(`${process.env.VUE_APP_API_BASE_PATH}/settings/section_names`, { withCredentials: true });
          this.sections = response.data.value[0].value.map((element) => ({
            value: element.section,
            name: element.section,
          }));
        } catch (error) {
          console.error('Error loading sections:', error);
        }
      },
      async getEvents() {
        let url = `${process.env.VUE_APP_API_BASE_PATH}/events?page=${this.parameters.page}`;
        if (this.parameters.brandAndRef) {
          url += `&simplesearch=${this.parameters.brandAndRef}`;
        }
        if (this.parameters.section !== -1) {
          url += `&product.section=${this.parameters.section}`;
        }
        if(this.parameters.dateStart instanceof Date && !isNaN(this.parameters.dateStart)){
          url += `&createdAt[after]=${this.parameters.dateStart.toISOString()}`;
        }
        if(this.parameters.dateEnd instanceof Date && !isNaN(this.parameters.dateEnd)){
          url += `&createdAt[before]=${this.parameters.dateEnd.toISOString()}`;
        }
        this.settings.forEach((setting) => {
          if(setting.changed){
            url += `&order[${setting.column}]=${setting.order}`;
          }
        })
        let everyThingIsOn = true;
        let hasAlertTypes = false;
        this.parameters.alertTypes.forEach((alertType) => {
          if(!alertType.isChecked){
            everyThingIsOn = false
          }
          if(alertType.isChecked && alertType.type !== 'none'){
            hasAlertTypes = true;
          }
        })
        if(!everyThingIsOn && hasAlertTypes){
          this.parameters.alertTypes.find(alert => alert.type === 'none').isChecked = false;
        } 
        if(!everyThingIsOn){
          this.parameters.alertTypes.forEach((alertType) => {
            if(alertType.isChecked && alertType.type !== 'none'){
              url += `&alerts.type[]=${alertType.type}`;
            } else if(alertType.type == 'none' && alertType.isChecked) {
              url += `&exists[alerts]=false`
            }
          })
        }
  
        try {
          const response = await axios.get(url, { withCredentials: true });
          if (response.status === 200) {
            this.events = [];
            response.data['hydra:member'].forEach(event => this.events.push(new Event(event)))
            this.handlePagination(response.data);
            this.loaded = true;
            this.setNewPath();
          }
        } catch (error) {
          console.error('Error fetching events:', error);
          this.events = [];
        }
      },
      handlePagination(data) {
        if(data['hydra:view'] && data['hydra:totalItems'] > 30){
            this.parameters.page = parseInt((JSON.parse('{"' + decodeURI(data['hydra:view']["@id"].split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'))["page"])
            this.parameters.page = !this.parameters.page ? 1 : this.parameters.page
            data['hydra:view']["hydra:last"] ? this.pageTotal = parseInt((JSON.parse('{"' + decodeURI(data['hydra:view']["hydra:last"].split('?')[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}'))["page"]) : this.pageTotal = this.parameters.page
        } else {
          this.parameters.page = 1;
          this.pageTotal = 1;
        }
      },
      async nextPage(){
          if(this.parameters.page < this.pageTotal){
              this.parameters.page++
              this.getEvents()
          }
      },
      async previousPage(){
          if(this.parameters.page > 1){
              this.parameters.page--
              this.getEvents()
          }
      },
      handleWebSocketMessage(data) {
            if (data && data.resource && data.resource == '/api/events') {
                this.getEvents();
            } 
        },

      log(action) {
        console.log(action);
      },
      toggleOrder() {
        this.parameters.dateOrder = this.parameters.dateOrder === 'DESC' ? 'ASC' : 'DESC';
        this.dateOrderIcon = this.parameters.dateOrder === 'ASC' ? 'ChevronUpIcon' : 'ChevronDownIcon';
        this.getEvents();
      },
      resetFilters() {
        this.parameters.section = -1;
        this.parameters.brandAndRef = null;
        this.settings.forEach((setting) => {
          if(['createdAt', 'totalAfterGlobal', 'totalAfterByLocation', 'differenceProduct', 'differenceProduct'].includes(setting.column)) {
            setting.order = 'desc'
            setting.changed = false
          }
        })
        this.getEvents();
      },
      handleOpenevent(eventId) {
        this.additionalInfoVisible[eventId] = !this.additionalInfoVisible[eventId];
      },
      calculateButtonState(totalCount, minevent) {
        if (totalCount === 0) {
          return 'danger';
        } else if (minevent !== 'N/A' && totalCount <= minevent) {
          return 'warning';
        } else if (minevent === 'N/A' && totalCount <= 5) {
          return 'warning';
        }
        return 'neutral';
      },
      eventLabel(event) {
        return event.totalCount !== undefined ? event.totalCount.toString() : 'N/A';
      },
      setNewPath() {
        const newQuery = {};
        if (this.parameters.dateOrder) newQuery.dateOrder = this.parameters.dateOrder;
        if (this.parameters.page && this.parameters.page !== 1) newQuery.page = this.parameters.page;
        if (this.parameters.section && this.parameters.section !== -1) newQuery.section = this.parameters.section;
        if (this.parameters.brandAndRef) newQuery.brandAndRef = this.parameters.brandAndRef;
  
        const fullPath = `/events${Object.keys(newQuery).length > 0 ? '?' + new URLSearchParams(newQuery).toString() : ''}`;
        window.localStorage.setItem('lastPath', fullPath);
      },
      toggleSettingOrder(setting){
        setting.order = setting.order === 'desc' ? 'asc' : 'desc';
        setting.changed = true
        this.getEvents()
      },
    },
  };
  </script>
  