<template>
    <div class="w-full mx-auto" :class="isMobile ? 'relative top-[81px]' : ''">
      <span class="text-title-3 p-2 mt-6 flex flex-row justify-center">
        Association Produit / Module
      </span>
      <div class="p-2 mt-6 flex flex-row justify-between" :class="productMessage ? 'items-center' : 'items-end'">
        <InputVue class="w-full" :label="'Entrer ou Scanner un Code Barre'" :placeholder="'Code Barre'" v-model="barCode" :height="'large'" :state="productState" :message="productMessage" />
        <ButtonVue class="ml-2" :filling="'filled'" :label="null" :height="'large'" :icon="'MagnifyingGlassIcon'" :state="'neutral'" @click="getProduct(barCode)" />
        <ButtonVue class="ml-2" :filling="'filled'" :label="null" :height="'large'" :icon="'CameraIcon'" :state="'default'" @click="openSheet('product')" />
      </div>
  
      <div v-if="product" class="w-full flex flex-col font bg-gray-100 text-gray-700 rounded-t-xl h-66 shadow-xl">
        <div class="w-50 flex flex-row items-center justify-center mt-5">
          <img :src="product.imageUrl" class="h-[40vh]" />
        </div>
        <div class="flex flex-col p-4">
          <span class="font-bold text-2xl"> {{ product.brand }}</span>
          <span class="text-l"> Reference : {{ product.reference }}</span>
          <span> Poids : {{ product.weight }} g</span>
        </div>
        <div class="flex flex-row justify-between px-2 pb-10">
          <ButtonVue :filling="'filled'" :label="'CALIBRER POIDS'" :height="'large'" :state="'default'" @click="openSheet('module', false)" />
          <ButtonVue :filling="'filled'" :label="'PLACER (' + modules.length + ')'" :height="'large'" :state="'default'" @click="openSheet('module', false)" />
        </div>
      </div>
  
      <div v-else-if="productState === 'danger'" class="flex flex-col p-2 mt-2">
        <span class="mb-4 mx-1 text-neutral-700 text-title-3">
          Veuillez rentrer les informations manuelement
        </span>
        <span class="mb-1 mx-1 text-neutral-700 text-body-5">
          Photo du produit
        </span>
        <input type="file" @input="saveFile($event)" accept="image/*,.pdf" class="block w-full text-body-5 text-neutral-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-body-5 file:font-semibold file:bg-primary-100 file:text-white hover:file:bg-primary-100" />
        <div class="flex flex-row w-full p-4">
          <div class="mr-4 w-50%">
            <InputVue :label="'Marque'" :placeholder="'Marque'" v-model="brand" :height="'large'" :state="productBrandState" :message="productBrandMessage" />
          </div>
          <div class="w-50%">
            <InputVue :label="'Reference'" :placeholder="'Reference'" v-model="reference" :height="'large'" :state="productReferenceState" :message="productReferenceMessage" />
          </div>
        </div>
        <div class="flex flex-row w-full p-4">
          <div class="mr-4 w-50%">
            <InputVue :label="'Poids Net'" :placeholder="'Poids Net'" v-model="weight" :height="'large'" :state="productWeightState" :message="productWeightMessage" />
          </div>
          <div class="w-50%">
            <InputVue :label="'Poids'" :placeholder="'Poids'" v-model="netWeight" :height="'large'" :state="productNetWeightState" :message="productNetWeightMessage" />
          </div>
        </div>
        <div class="mt-4">
          <ButtonVue :filling="'filled'" :label="'Enregistrer Nouveau Produit'" :height="'large'" :state="'default'" @click="saveProduct()" />
        </div>
      </div>
  
      <!-- Bottom Sheets -->
      <BottomSheetVue v-if="sheetProduct" @closeModal="sheetProduct = false">
        <template v-slot:body>
          <div class="w-full p-2 flex flex-col justify-start items-center">
            <span class="text-title-3 p-2 my-6 flex flex-row justify-center">
              Placer un produit devant l'appareil photo
            </span>
            <LoadingVue class="w-full h-[100px]" v-if="!loaded" />
            <div id="data-capture-view" class="mb-6 w-100% h-[50vh]" ></div>
            <!--<BarcodeReaderVue class="mb-6" @decode="onBarCode" @loaded="loaded = true" />-->
          </div>
        </template>
      </BottomSheetVue>
      <BottomSheetVue v-if="sheetModule" @closeModal="sheetModule = false">
        <template v-slot:body>
          <div class="w-full p-2 flex flex-col justify-start items-center">
            <span class="text-title-3 p-2 my-6 flex flex-row justify-center">
              Scanner les QR code de modules successivement ({{ modules.length }})
            </span>
            <LoadingVue class="w-full h-[100px]" v-if="!loaded" />
            <qrcode-stream class="mb-6 w-100% h-[50vh]" @decode="onQrCode" @init="onInit" />
            <ButtonVue class="w-full my-6" :filling="'filled'" :label="'ASSOCIER (' + modules.length + ')'" :height="'large'" :state="modules.length ? 'default' : 'disabled'" @click="associateProduct()" />
          </div>
        </template>
      </BottomSheetVue>
    </div>
  </template>
  

  <script>
import axios from 'axios';
import Product from '@/utils/entities/Product';
import LoadingVue from '../components/elements/Loading.vue';
import { QrcodeStream } from 'vue3-qrcode-reader';
import InputVue from '../components/elements/Input.vue';
import ButtonVue from '../components/elements/Button.vue';
import BottomSheetVue from '../components/containers/BottomSheet.vue';
import * as SDCCore from 'scandit-web-datacapture-core';
import * as SDCBarcode from 'scandit-web-datacapture-barcode';


export default {
  name: 'ScannerView',
  components: {
    LoadingVue,
    QrcodeStream,
    InputVue,
    ButtonVue,
    BottomSheetVue,
  },
  data() {
    return {
      barCode: null,
      sheetProduct: false,
      sheetModule: false,
      productState: 'default',
      productMessage: null,
      loaded: false,
      product: null,
      modules: [],
      fileUrl: null,
      brand: null,
      productBrandState: 'default',
      productBrandMessage: null,
      reference: null,
      productReferenceState: 'default',
      productReferenceMessage: null,
      weight: null,
      productWeightState: 'default',
      productWeightMessage: null,
      netWeight: null,
      productNetWeightState: 'default',
      productNetWeightMessage: null,
    };
  },
  watch:{
    async sheetProduct() {
      if(this.sheetProduct){
        await SDCCore.configure({
          licenseKey: process.env.VUE_APP_SCANDIT_LICENSE_KEY,
          libraryLocation: 'https://cdn.jsdelivr.net/npm/scandit-web-datacapture-barcode@6.x/build/engine/',
          moduleLoaders: [SDCBarcode.barcodeCaptureLoader()],
        });
        const context = await SDCCore.DataCaptureContext.create();
        const view = await SDCCore.DataCaptureView.forContext(context);
        const element = document.getElementById('data-capture-view');
        view.connectToElement(element);

        const settings = new SDCBarcode.BarcodeCaptureSettings();
        settings.enableSymbologies([
          SDCBarcode.Symbology.Code128,
          SDCBarcode.Symbology.Code39,
          SDCBarcode.Symbology.QR,
          SDCBarcode.Symbology.EAN8,
          SDCBarcode.Symbology.UPCE,
          SDCBarcode.Symbology.EAN13UPCA,
        ]);

        const barcodeCapture = await SDCBarcode.BarcodeCapture.forContext(context, settings);

        const camera = SDCCore.Camera.default;
        await camera.applySettings(SDCBarcode.BarcodeCapture.recommendedCameraSettings);
        await context.setFrameSource(camera);
        await camera.switchToDesiredState(SDCCore.FrameSourceState.On);

        barcodeCapture.addListener({
          didScan: async (barcodeCapture, session) => {
            const recognizedBarcodes = session.newlyRecognizedBarcodes;
            if (recognizedBarcodes.length > 0) {
              this.barCode = recognizedBarcodes[0]._data;
              console.log(this.barCode);
              barcodeCapture = null;
              this.sheetProduct = false;

              await camera.switchToDesiredState(SDCCore.FrameSourceState.Off);
              await this.getProduct(this.barCode);
            }
          },
        });
      }
    }
  },
  computed: {
        isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
  
  methods: {
    async saveFile(event) {
      const file = event.target.files[0];
      if (!file) return;

      let formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_PATH + '/media_objects', formData, {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 201) {
          this.fileUrl = process.env.VUE_APP_API_BASE_PATH.replace('/api', '') + response.data.contentUrl;
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    async onBarCode(result) {
      this.barCode = result;
      await this.getProduct(result);
      this.sheetProduct = false;
    },
    onQrCode(result) {
      this.addModule(result);
    },
    async onInit(promise) {
      try {
        await promise;
        this.loaded = true;
      } catch (error) {
        this.handleCameraError(error);
      }
    },
    async getProduct(barcode) {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE_PATH}/products/${barcode}`, { withCredentials: true });
        if (response.status === 200) {
          this.product = new Product(response.data);
          this.productMessage = null;
          this.productState = 'default';
        }
      } catch {
        this.resetProductForm('Code Barre non reconnu', 'danger');
      }
    },
    async saveProduct() {
      if (!this.validateProductForm()) return;

      try {
        const response = await axios.post(`${process.env.VUE_APP_API_BASE_PATH}/products`, {
          reference: this.reference,
          barCode: this.barCode,
          netWeight: parseInt(this.netWeight),
          weight: parseInt(this.weight),
          imageUrl: this.fileUrl,
          brand: this.brand,
        }, { withCredentials: true });

        if (response.status === 201) {
          this.product = response.data;
          this.loaded = true;
          this.productMessage = null;
          this.productState = 'default';
        }
      } catch (error) {
        console.error('Error saving product:', error);
      }
    },
    validateProductForm() {
      let valid = true;
      if (!this.brand) {
        this.productBrandState = 'danger';
        this.productBrandMessage = 'Champ Requis';
        valid = false;
      }
      if (!this.reference) {
        this.productReferenceState = 'danger';
        this.productReferenceMessage = 'Champ Requis';
        valid = false;
      }
      if (!this.weight) {
        this.productWeightState = 'danger';
        this.productWeightMessage = 'Champ Requis';
        valid = false;
      }
      if (!this.netWeight) {
        this.productNetWeightState = 'danger';
        this.productNetWeightMessage = 'Champ Requis';
        valid = false;
      }
      return valid;
    },
    addModule(qrcode) {
      if (!this.modules.includes(qrcode)) {
        this.modules.push(qrcode);
      }
    },
    async associateProduct() {
      if (this.product) {
        try {
          const json = { modules: this.modules };
          await this.product.associateProduct(this.product.barCode, json);
          this.sheetModule = false;
        } catch (error) {
          console.error('Error associating product:', error);
        }
      }
    },
    handleCameraError(error) {
      const errorMessages = {
        NotAllowedError: "ERROR: you need to grant camera access permission",
        NotFoundError: "ERROR: no camera on this device",
        NotSupportedError: "ERROR: secure context required (HTTPS, localhost)",
        NotReadableError: "ERROR: is the camera already in use?",
        OverconstrainedError: "ERROR: installed cameras are not suitable",
        StreamApiNotSupportedError: "ERROR: Stream API is not supported in this browser",
        InsecureContextError: 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.',
      };
      this.error = errorMessages[error.name] || `ERROR: Camera error (${error.name})`;
      console.error(this.error);
    },
    resetProductForm(message, state) {
      this.product = null;
      this.productMessage = message;
      this.productState = state;
      this.brand = null;
      this.reference = null;
      this.weight = null;
      this.netWeight = null;
    },
    openSheet(sheet, loadedState = true) {
      this[`sheet${sheet.charAt(0).toUpperCase() + sheet.slice(1)}`] = true;
      this.loaded = loadedState;
    },
  },
};
</script>
