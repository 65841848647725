// src/plugins/devicePlugin.js

export default {
    install(app) {
      const device = {
        get isMobile() {
          return window.innerWidth <= 768;
        },
        get isPortrait() {
          return window.innerHeight > window.innerWidth;
        },
      };
      app.config.globalProperties.$device = device;
    },
  };
  