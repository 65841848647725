import { createRouter, createWebHistory } from 'vue-router'

import DashboardView from '../views/DashboardView.vue'
import ScannerView from '../views/ScannerView.vue'
import MapEditView from '../views/MapEditView.vue'
import MapView from '../views/MapView.vue'
import ProductView from '../views/ProductView.vue'
import ProductsView from '../views/ProductsView.vue'
import ProductCreateView from '../views/ProductCreateView.vue'
import ShelfView from '../views/ShelfView.vue'
import ShelfViewerView from '../views/ShelfViewerView.vue'
import ShelvesView from '../views/ShelvesView.vue'
import SettingsView from '../views/SettingsView.vue'
import PageNotFoundView from '../views/PageNotFoundView.vue'
import ApiView from '../views/ApiView.vue'
import EventsView from '../views/EventsView.vue'


import ShelfViewMobile from '../views/ShelfViewMobile.vue'
import NotificationCenter from '../views/NotificationCenterView.vue'

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView
  },
  {
    path: '/scanner',
    name: 'ScannerView',
    component: ScannerView
  },
  {
    path: '/map',
    name: 'MapView',
    component: MapView
  },
  {
    path: '/mapEditor',
    name: 'MapEditorView',
    component: MapEditView
  },
  {
    path: '/products',
    name: 'ProductsView',
    component: ProductsView
  },
  {
    path: '/events',
    name: 'EventsView',
    component: EventsView
  },
  {
    path: '/shelf/:id',
    name: 'ShelfView',
    component: ShelfView
  },
  // to delete once integrated *********
  {
    path: '/shelfmobile',
    name: 'ShelfViewMobile',
    component: ShelfViewMobile
  },
  {
    path: '/notification',
    name: 'NotificationCenter',
    component: NotificationCenter
  },
  // ************************************
  {
    path: '/product/:id',
    name: 'ProductView',
    component: ProductView
  },
  {
    path: '/product_create',
    name: 'ProductCreateView',
    component: ProductCreateView
  },
  {
    path: '/shelf_viewer',
    name: 'ShelfViewerView',
    component: ShelfViewerView
  },
  {
    path: '/shelves',
    name: 'ShelvesView',
    component: ShelvesView
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFoundView
  },
  {
    path: '/settings',
    name: "SettingsView",
    component: SettingsView
  },
  {
    path: '/api_view',
    name: "ApiView",
    component: ApiView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
