<template>
    <nav class="fixed z-50 top-0 flex items-center justify-between flex-no-wrap bg-gray-200 px-5 shadow-sm w-full touch-none"
        :class="!isMobile ? 'h-[100px]' : 'h-[70px]'">
        <img :src="tekshelfLogo" width="120" />
        <ul v-if="!isMobile" class="flex flex-row justify-between items-center">
            <NotificationVue ref="notification" v-model="notifications"/>
            <li v-for="(value, index) in routesDesktop" :key="index" class="mr-1" @click="$refs.notification.close()">
                <router-link :to="value.path" >
                    <a :class="tab === index ? 'border-b-2 border-primary-800 text-primary-800' : 'text-neutral-700 hover:text-neutral-200'"
                        class="bg-transparent flex flex-row  py-2 px-4 font-semibold"
                        href="#">
                        <span v-if="!value.icon">{{ value.name }}</span>
                        <span v-else><component :is="heroIcons[value.icon]" fill="black" class="stroke-2 h-6 w-6"/></span>
                    </a>
                </router-link>
            </li>
        </ul>
        <div v-else class="flex flex-row justify-between items-center">
            <NotificationVue v-model="notifications" />
            <component :is="heroIcons['Bars3Icon']" fill="black" class="stroke-1 h-10 w-10 mx-5"
                @click="menu = menu ? false : true" />
            <RightSheetVue v-if="menu" @closeModal="menu = false">
                <template v-slot:body>
                    <ul class="w-full h-full flex flex-col pt-1 mr-5 items-end overflow-y-auto z-40 pb-20">
                        <li v-for="(value, index) in routesMobile" :key="index" class="my-5" @click="menu = false">
                            <router-link :to="value.path">
                                <a :class="tab === index ? 'border-b-2 border-primary-800 text-primary-800' : 'text-neutral-700 hover:text-neutral-200'"
                                    class="bg-transparent inline-block  py-2 px-4 font-semibold"
                                    href="#">{{ value.name }}</a>
                            </router-link>
                        </li>
                        <li class="my-5 bg-transparent inline-block py-2 px-4 hover:text-neutral-200 font-semibold text-danger-600" @click="logout()">Deconnexion</li>
                    </ul>
                </template>
            </RightSheetVue>
        </div>
    </nav>
    <ul v-if="toasts.length" class="fixed z-10 right-[10px] flex flex-col w-full md:w-full lg:w-50% xl:w-34% touch-none"
        :class="!isMobile ? 'top-[110px]' : 'top-[80px]'">
        <li v-for="(value, index) in toasts" :key="index"
            class="w-full rounded-md mb-[10px] bg-gray-100 shadow-lg border border-gray-200 relative">
            <div @click="removeToast(index)"
                class="rounded-[1rem] absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 h-6 w-6 bg-gray-600 text-white flex justify-center items-center">
                <component :is="heroIcons['XMarkIcon']" fill="currentColor" class="h-4 w-4"/>
            </div>
            <div v-if="!value.seenAt" class="border-l-4 h-full flex flex-row items-center justify-start p-4 w-full"
                :class="getTypeColor(value.type, 'border')">
                <router-link :to="redirectUri(value.resourceUri)">
                <div class="flex flex-row items-center">
                    <component :is="getTypeIcon(value.type)" fill="currentColor" class="mr-1 h-6 w-6"
                        :class="getTypeColor(value.type, 'text')" />
                    <div class="flex flex-col justify-start items-start w-85% ml-2">
                        <span class="text-body-1">{{ value.title }}</span>
                        <span class="text-body-3">{{ value.message }}</span>
                    </div>
                </div>
                </router-link>
            </div>
        </li>
    </ul>
</template>

<script>

import axios from 'axios'
import * as heroIcons from '@heroicons/vue/24/outline'
import NotificationVue from '@/components/elements/Notification.vue'
import RightSheetVue from '@/components/containers/RightSheet.vue'
import WebsocketService from '@/utils/WebsocketService';

export default ({
    name: 'NavbarVue',
    components: {
        NotificationVue,
        RightSheetVue
    },
    data() {
        return {
            heroIcons: heroIcons,
            tekshelfLogo: require('../../../assets/cropped-LOGO_TEKSHELF-removebg-preview-2-160x53.png'),
            tab: null,
            loaded: false,
            firstLoad: true,
            toasts: [],
            notifications: [],
            routesDesktop: [
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                    icon: null,
                },
                {
                    path: '/map',
                    name: 'Map',
                    icon: null,
                },
                {
                    path: '/products',
                    name: 'Products',
                    icon: null,
                },
                {
                    path: '/events',
                    name: 'Events',
                    icon: null,
                },
                {
                    path: '/shelves',
                    name: 'Shelves',
                    icon: null,
                },
                {
                    path: '/settings',
                    name: 'Settings',
                    icon: 'CogIcon',
                },
            ],
            routesMobile: [
                {
                    path: '/scanner',
                    name: 'Scanner',
                },
                {
                    path: '/dashboard',
                    name: 'Dashboard',
                },
                {
                    path: '/map',
                    name: 'Map',
                },
                {
                    path: '/products',
                    name: 'Products',
                },
                {
                    path: '/shelves',
                    name: 'Shelves',
                },
                {
                    path: '/settings',
                    name: 'Settings',
                },
            ],
            menu: false,
        }
    },
    computed: {
        isMobile() {
            return this.$device.isMobile;
        },
        isPortrait() {
            return this.$device.isPortrait;
        },
    },
    async mounted() {       
        this.setTab();
        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        await this.getAlerts().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                WebsocketService.sendMessage({
                    command: 'subscribe',
                    resource: '/api/alerts',
                });
            });    
        })


        WebsocketService.addListener((type, data) => {
        if (type === 'message') {
            this.handleWebSocketMessage(data);
        }
        });
    },
    methods: {
        async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000);
            });
        },

        handleWebSocketMessage(data) {
            if (["update", "create", "delete"].includes(data.command) && data.resource.includes('/api/alerts')) {
                this.getAlerts();
                this.addToast(data.value)
            }
        },

        setTab() {
            this.isMobile ? this.tab = this.routesMobile.findIndex(element => element.path === this.$router.currentRoute.value.fullPath) : this.tab = this.routesDesktop.findIndex(element => element.path === this.$router.currentRoute.value.fullPath);
        },

        async getAlerts() {
            let url = process.env.VUE_APP_API_BASE_PATH + '/alerts';
           
           /**
            * ?page=' + this.pageNumber
            * if (this.filter.length > 0) {
            *     url += '&type=' + this.filter;
            * }
            */

            await axios
                .get(url, { withCredentials: true })
                .then((response) => {
                    this.notifications = response.data['hydra:member'];
                    this.$forceUpdate()
                });
            
        },

        redirectUri(resourceUri) {
            var resourceArray = resourceUri.split('/');
            switch (resourceArray[2]) {
                case 'linears':
                    return '/shelf/' + resourceArray[3];
            }
            return "/"
        },
        getTypeColor(type, text) {
            switch (type) {
                case "warning":
                    return text + "-warning-500"
                case "danger":
                    return text + "-danger-500"
                case "success":
                    return text + "-success-500"
                case "maintenance":
                    return text + "-gray-500"
                case "security-high":
                    return text + "-primary-400"
                case "security-low":
                    return text + "-primary-800"
                    

            }
        },

        getTypeIcon(type) {
            switch (type) {
                case "warning":
                    return this.heroIcons['ExclamationTriangleIcon']
                case "danger":
                    return this.heroIcons['XCircleIcon']
                case "success":
                    return this.heroIcons['CheckCircleIcon']
                case "maintenance":
                    return this.heroIcons['Cog8ToothIcon']
                case "security-high":
                    return this.heroIcons['ShieldCheckIcon']
                case "security-low":
                    return this.heroIcons['LockClosedIcon']

            }
        },
        getLink(value) {
            if (value) {
                switch (value.split('/')[2]) {
                    case 'linears':
                        return '/shelf/' + value.split('/')[3];
                    default:
                        return '/dashboard';
                }
            }
        },
        addToast(notif) {
            this.toasts.push(notif);
            setTimeout(() => {
                this.toasts.shift();
            }, 5000);
        },
        removeToast(index){
            this.toasts.splice(index, 1);
        },
        async logout() {
            await this.$axios
                .post(process.env.VUE_APP_API_BASE_PATH+'/logout', {}, { withCredentials: true })
                .then(response => {
                    if(response.status == 200){
                        window.location.replace('https://tekshelf.fr');
                    }
                })
                .catch(() => {});
        },
    },
    watch: {
        '$router.currentRoute.value.fullPath': {
            handler() {
                this.setTab();
            },
            deep: true,
            immediate: true
        },
    }
});
</script>


<style scoped>
.slideleft-enter-from,
.slideleft-leave-to {
    transform: translate(100%, 0);
}

.slideleft-enter-active,
.slideleft-leave-active {
    transition: all .3s ease-in-out;
}

.slidedown-enter-from,
.slidedown-leave-to {
    min-height: 100%;
}

.slidedown-enter-active,
.slidedown-leave-active {
    transition: all .3s ease-in-out;
}


.fade-enter-active,
.fade-leave-active {
    transition: all .3s linear;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}
</style>