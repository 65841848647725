import axios from 'axios'
import Product from './Product'
import WebsocketService from '../WebsocketService'

export default class Event {

    constructor(event = {}) {
        this.build(event);
    }

    build(event){
        this.id = event.id || null;
        this.product = new Product(event.product) || null;
        this.location = event.location || [];
        this.createdAt = event.createdAt || null;
        this.differenceWeight = event.differenceWeight || null;
        this.differenceProduct = event.differenceProduct || null;
        this.totalAfterByLocation = event.totalAfterByLocation || null;
        this.totalAfterGlobal = event.totalAfterGlobal || null;
        this.alerts = event.alerts || [];
    }

    initializeWebSocketListener() {
        if (this.id) {
            WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

            WebsocketService.sendMessage({
                command: 'subscribe',
                resource: `/api/events/${this.id}`,
            });

            WebsocketService.addListener(async (type, data) => {
                if (type === 'message' && data.resource === `/api/events/${this.id}` && data.command === "update") {
                    await this.retrieveEvent();
                }
            });
        }
    }

    unsubscribeWebSocket() {
        if (this.id) {
            WebsocketService.sendMessage({
                command: 'unsubscribe',
                resource: `/api/events/${this.id}`,
            });
        }
    }

    // CREATE
    async createEvent(eventData) {
        await axios
            .post(process.env.VUE_APP_API_BASE_PATH + '/events', eventData, { withCredentials: true })
            .then(response => {
                this.build(response.data);
            });
    }

    // READ
    async retrieveEvent() {
        if (this.id) {
            await axios
                .get(process.env.VUE_APP_API_BASE_PATH + '/events/' + this.id, { withCredentials: true })
                .then(response => {
                    if (response.status === 200) {
                        this.build(response.data);
                    }
                });
        }
    }

    // UPDATE
    async updateEvent(eventData) {
        if (this.id) {
            await axios
                .patch(process.env.VUE_APP_API_BASE_PATH + '/events/' + this.id, eventData, {
                    withCredentials: true,
                    headers: { 'Content-Type': 'application/merge-patch+json' }
                })
                .then(response => {
                    this.build(response.data);
                });
        }
    }

    // DELETE
    async deleteEvent() {
        if (this.id) {
            await axios
                .delete(process.env.VUE_APP_API_BASE_PATH + '/events/' + this.id, { withCredentials: true })
                .then(() => {
                    this.id = null;
                    this.product = null;
                    this.location = [];
                    this.createdAt = null;
                    this.differenceWeight = null;
                    this.differenceProduct = null;
                    this.totalAfterByLocation = null;
                    this.totalAfterGlobal = null;
                    this.alerts = [];
                });
        }
    }
}
