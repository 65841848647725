<template lang="html">
    <div class="flex flex-col justify-start align-top">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling(styles.span_label)">
            {{label}}
        </span>
            <div class="relative w-full">
                <input
                ref="input"
                class="rounded-md text-neutral-600 box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 focus:border-primary-400 focus:ring-primary-400 shadow-sm hover:shadow-lg focus:shadow-xs"
                :type="type === 'text' || (type === 'password' && showPassword) ? 'text' : 'password'"
                :class="type === 'password' ? styling(styles.password) : styling(styles.text)"
                v-model="dataValue" 
                :placeholder="placeholder"
                @input="$emit('update:modelValue', dataValue)"
                :disabled="state === 'disabled' ? 'disabled' : null"
                :readonly="state === 'readonly' ? 'readonly' : null"
                />
                <component v-if="type === 'password'" 
                :is="showPassword ? heroIcons['EyeSlashIcon'] : heroIcons['EyeIcon'] " 
                class="absolute stroke-1 stroke-neutral-600" 
                :class="styling(styles.icon_eye)" 
                @click="showPassword = !showPassword"/>
            </div>
            <span v-if="message" 
            class="mt-1 mx-1 inline-flex items-center" 
            :class="styling(styles.span_message)">
                <component
                :is="heroIcons[styles.icon_message[state]]" 
                fill="currentColor" 
                class="mr-1" 
                :class="styling(styles.icon_message)"/>
                {{message}}
            </span>
            <span v-if="helper" 
            class="mt-1 mx-1 text-neutral-400" 
            :class="styling(styles.span_helper)">
                {{helper}}
            </span>
        </div>
  </template>

<script>

import * as heroIcons from '@heroicons/vue/24/outline'
  
export default  {
  name: 'InputVue',
  props: {
    modelValue: {
        default: ''
    },
    height: {
        type: String,
        validator(value) {
            return ['large', 'medium', 'small'].includes(value)
        }, 
        default: 'medium'
    },
    type: {
        type: String,
        validator(value) {
            return ['text', 'password'].includes(value)
        }, 
        default: 'text'
    },
    placeholder: {
        type: String,
        default: 'Insert text here'
    },
    label: {
        type: String,
        default: ''
    },
    message: {
        type: String,
        default: ''
    },
    helper: {
        type: String,
        default: ''
    },
    state: {
        type: String,
        validator(value) {
            return ['default', 'success', 'danger', 'warning', 'disabled', 'readonly'].includes(value)
        }, 
        default: 'default'
    },
  },
  watch: {
    modelValue: {
        handler() {
            this.dataValue = this.modelValue
        },
        deep: true
    },
    height: {
      handler() {},
      deep: true
    },
    type: {
      handler() {},
      deep: true
    },
    placeholder: {
      handler() {},
      deep: true
    },
    label: {
      handler() {},
      deep: true
    },
    message: {
      handler() {},
      deep: true
    },
    helper: {
      handler() {},
      deep: true
    },
    state: {
      handler() {},
      deep: true
    },
  },
  data () {
    return {
      heroIcons: heroIcons,
      dataValue: '',
      showPassword: false,
      styles: {
        'span_label' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'readonly': 'border-neutral-500 hover:border-neutral-300',
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'password' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[2.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[2.25rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1.75rem]', 
            },
            'state' : {
                'readonly': 'border-neutral-500 hover:border-neutral-300',
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'readonly': 'text-neutral-600',
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'readonly': '',
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'icon_eye' : {
            'height' : {
                'large': 'h-6 w-6 left-[0.75rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 left-[0.65rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 left-[0.5rem] inset-y-[0.5rem]',
            },
        }
      }
    }
  },
  created(){
    this.dataValue = this.modelValue
  },
  methods: {
    getState(object){
        switch (this.state){
            case 'default':{
                return object.default
            }
            case 'success':{
                return object.success
            }
            case 'danger':{
                return object.danger
            }
            case 'warning':{
                return object.warning
            }
            case 'disabled':{
                return object.disabled
            }
            case 'readonly':{
                return object.readonly
            }
        }

    },
    getHeight(object){
        switch (this.height){
            case 'large':{
                return object.large
            }
            case 'medium':{
                return object.medium
            }
            case 'small':{
                return object.small
            }
        }
    },
    styling(object){
        var valren = ''
        object.height ? valren += this.getHeight(object.height) +' ' : null
        object.state ? valren += this.getState(object.state) +' ' : null
        return valren
    },
    addFocus(){
        this.$refs.input.focus();
    },

    addBlur(){
        this.$refs.input.blur();
    }
  },
}


</script>
  
  