<template class="mx-auto">
    <div v-if="isMobile" class="w-full h-auto flex flex-col bg-gray-50 relative top-[80px] p-4">
        <!-- Mobile View -->
        <LoadingVue v-if="!product && !loaded" />
        <div v-else class="w-full flex flex-col">
            <div id="ProductInfo" class="mb-4">
                <div class="text-title-2 mb-4">{{ product.barCode }}</div>
                <div @click="toggleBody('ProductInfoBody')" class="border-b-2 border-gray-200 px-4 py-2 flex justify-between items-center" :class="{ 'bg-gray-200': bodyVisibility.ProductInfoBody }">
                    <p class="text-md font-semibold">Produit</p>
                    <ButtonVue :filling="'text'" :label="null" :icon="bodyVisibility.ProductInfoBody ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </div>
                <div v-show="bodyVisibility.ProductInfoBody" class="px-4 py-4">
                    <div class="flex flex-col items-center">
                        <div class="w-full flex justify-center mb-4">
                            <img :src="product.imageUrl" class="max-w-[70%]" />
                        </div>
                        <div class="flex flex-col justify-start align-top mt-10">
                            <label for="file-upload" class="mb-2 mx-1 text-neutral-700 text-sm font-medium">
                                Photo
                            </label>
                            <input id="file-upload" type="file" @input="saveFile($event)" accept="image/*,.pdf" class="block w-full text-body-5 text-neutral-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-body-5 file:font-semibold
                                file:bg-primary-100 file:text-white
                                hover:file:bg-primary-100
                            "/>
                        </div>
                        <div class="flex flex-row justify-between w-full mt-4">
                            <InputVue class="w-full mr-1"  :label="'Marque'" v-model="product.brand" />
                            <InputVue class="w-full ml-1" :label="'Référence'" v-model="product.reference" />
                        </div>
                        <div class="flex flex-row justify-between items-center w-full mt-4">
                            <SelectVue class="w-full mr-1" :label="'Catégorie de Produit'" v-model="product.section" :options="sections" />
                            <CheckboxVue class="w-full ml-1 mt-4" :label="'Produit sensible ?'" v-model="product.isSensitive" />
                        </div>
                        <div class="flex flex-row justify-between w-full mt-4">
                            <InputVue class="w-full mr-1" :label="'Poids Net'" :placeholder="'Poids Net'" v-model="product.netWeight" />
                            <InputVue class="w-full ml-1" :label="'Poids'" :placeholder="'Poids'" v-model="product.weight" />
                        </div>
                        <div class="flex flex-row justify-between w-full mt-4">
                            <InputVue class="w-full mr-1" :label="'Seuil Critique'" :placeholder="'Seuil Critique'" v-model="product.minProduct" />
                            <InputVue class="w-full ml-1" :label="'DLC moyen'" :placeholder="'DLC moyen'" v-model="product.expiringOffset" />
                        </div>
                        <ButtonVue class="w-full mt-10" :filling="'filled'" :label="'Enregistrer Produit'" @click="saveProduct()" />
                    </div>
                </div>
            </div>

            <!-- RefDataset -->
            <div id="RefDataset" class="mb-4">
                <div @click="toggleBody('RefDatasetBody')" class="border-b-2 border-gray-200 px-4 py-2 flex justify-between items-center" :class="{ 'bg-gray-200': bodyVisibility.RefDatasetBody }">
                    <p class="text-md font-semibold">Dataset de référence</p>
                    <ButtonVue :filling="'text'" :label="null" :icon="bodyVisibility.RefDatasetBody ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </div>
                <div v-show="bodyVisibility.RefDatasetBody" class="px-4 py-4">
                    <DatetimefieldVue class="w-full mt-2" v-model="filtersReference.dateStart" :label="'Depuis'" />
                    <DatetimefieldVue class="w-full mt-2" v-model="filtersReference.dateEnd" :label="'Jusqu\'à'" />
                    <ButtonVue class="w-full mt-4" :label="'Extrait Excel'" @click="product.getEventsExtract(0)" />
                </div>
            </div>

            <!-- CompDataset -->
            <div id="CompDataset" class="mb-4">
                <div @click="toggleBody('CompDatasetBody')" class="border-b-2 border-gray-200 px-4 py-2 flex justify-between items-center" :class="{ 'bg-gray-200': bodyVisibility.CompDatasetBody }">
                    <p class="text-md font-semibold">Dataset de comparaison</p>
                    <ButtonVue :filling="'text'" :label="null" :icon="bodyVisibility.CompDatasetBody ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </div>
                <div v-show="bodyVisibility.CompDatasetBody" class="px-4 py-4">
                    <AutocompleteVue class="w-full" v-model="brandAndRef" :label="'Rechercher un produit'" />
                    <DatetimefieldVue class="w-full mt-2" v-model="filtersComparaison.dateStart" :label="'Depuis'" />
                    <DatetimefieldVue class="w-full mt-2" v-model="filtersComparaison.dateEnd" :label="'Jusqu\'à'" />
                    <ButtonVue class="w-full mt-4" :label="'Extrait Excel'" @click="product.getEventsExtract(1)" />
                </div>
            </div>
            <SelectVue class="w-full mt-2" v-model="timePeriod" :options="timePeriodOptions" :label="'Période'" />

            <!-- Graph -->
            <div id="Graph" class="w-full mx-auto my-6 p-6 bg-white rounded-lg shadow-lg">
                <canvas id="chart" v-if="chartData"></canvas>
                <LoadingVue v-else />
            </div>
        </div>
    </div>

    <!-- Desktop View -->
    <div v-else class="w-full h-full flex flex-row bg-gray-50 relative top-[80px]">
        <div class="w-[35%] h-[calc(100vh-80px)] shadow-sm bg-gray-50 overflow-y-auto">
            <LoadingVue v-if="!product" />
            <div v-else class="h-full flex flex-col justify-between items-center px-4">
                <div class="w-full mt-10 flex flex-col items-center justify-start">
                    <div class="text-title-2 mb-4">{{ product.barCode }}</div>
                    <div class="w-full flex justify-center mb-4">
                        <img :src="product.imageUrl" class="max-w-[70%]" />
                    </div>
                    <div class="flex flex-col justify-start align-top mt-10">
                        <label for="file-upload" class="mb-2 mx-1 text-neutral-700 text-sm font-medium">
                            Photo
                        </label>
                        <input id="file-upload" type="file" @input="saveFile($event)" accept="image/*,.pdf" class="block w-full text-body-5 text-neutral-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-body-5 file:font-semibold
                            file:bg-primary-100 file:text-white
                            hover:file:bg-primary-100
                        "/>
                    </div>
                </div>
                <div class="w-full mb-6">
                    <div class="flex flex-row justify-between w-full mt-4">
                        <InputVue class="w-full mr-1" :height="'large'" :label="'Marque'" v-model="product.brand" />
                        <InputVue class="w-full ml-1" :height="'large'" :label="'Référence'" v-model="product.reference" />
                    </div>
                    <div class="flex flex-row justify-between items-center w-full mt-4">
                        <SelectVue class="w-full mr-1" :height="'large'" :label="'Catégorie de Produit'" v-model="product.section" :options="sections" />
                        <CheckboxVue class="w-full ml-1 mt-4" :height="'medium'" :label="'Produit sensible ?'" v-model="product.isSensitive" />
                    </div>
                    <div class="flex flex-row justify-between w-full mt-4">
                        <InputVue class="w-full mr-1" :height="'large'" :label="'Poids Net'" :placeholder="'Poids Net'" v-model="product.netWeight" />
                        <InputVue class="w-full ml-1" :height="'large'" :label="'Poids'" :placeholder="'Poids'" v-model="product.weight" />
                    </div>
                    <div class="flex flex-row justify-between w-full mt-4 ">
                        <InputVue class="w-full mr-1" :height="'large'" :label="'Seuil Critique'" :placeholder="'Seuil Critique'" v-model="product.minProduct" />
                        <InputVue class="w-full ml-1" :height="'large'" :label="'DLC moyen'" :placeholder="'DLC moyen'" v-model="product.expiringOffset" />
                    </div>
                    <ButtonVue class="w-full mt-10" :filling="'filled'" :label="'Enregistrer Produit'" @click="saveProduct()" />
                </div>
            </div>
        </div>

        <div class="w-full flex flex-col h-[calc(100vh-80px)] items-center justify-start overflow-y-auto">
            <LoadingVue v-if="!product" />
            <div v-else class="flex flex-row justify-between w-full">
                <div id="RefDataset" class="my-4 w-full relative">
                    <div @click="toggleBody('RefDatasetBody')" class="border-b-2 border-gray-200 p-4 flex justify-between items-center" :class="{ 'bg-gray-200': bodyVisibility.RefDatasetBody }">
                        <p class="text-md font-semibold">Dataset de référence</p>
                        <ButtonVue :filling="'text'" :label="null" :icon="bodyVisibility.RefDatasetBody ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                    </div>
                    <div v-show="bodyVisibility.RefDatasetBody" class="px-4 py-4 absolute top-[100%] left-0 w-full bg-gray-50 shadow-xl">
                        <DatetimefieldVue class="w-full" v-model="filtersReference.dateStart" :label="'Depuis'" />
                        <DatetimefieldVue class="w-full mt-2" v-model="filtersReference.dateEnd" :label="'Jusqu\'à'" />
                        <ButtonVue class="w-full mt-4" :label="'Extrait Excel'" @click="product.getEventsExtract(0)" />
                    </div>
                </div>

                <!-- CompDataset -->
                <div id="CompDataset" class="my-4 w-full relative">
                    <div @click="toggleBody('CompDatasetBody')" class="border-b-2 border-gray-200 p-4 flex justify-between items-center" :class="{ 'bg-gray-200': bodyVisibility.CompDatasetBody }">
                        <p class="text-md font-semibold">Dataset de comparaison</p>
                        <ButtonVue :filling="'text'" :label="null" :icon="bodyVisibility.CompDatasetBody ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                    </div>
                    <div v-show="bodyVisibility.CompDatasetBody" class="px-4 py-4 absolute top-[100%] left-0 w-full bg-gray-50 shadow-xl">
                        <AutocompleteVue class="w-full !z-50" v-model="filtersComparaison.barCode" :label="'Rechercher un produit'"/>
                        <DatetimefieldVue class="w-full mt-2" v-model="filtersComparaison.dateStart" :label="'Depuis'" />
                        <DatetimefieldVue class="w-full mt-2" v-model="filtersComparaison.dateEnd" :label="'Jusqu\'à'" />
                        <ButtonVue class="w-full mt-4" :label="'Extrait Excel'" @click="product.getEventsExtract(1)" />
                    </div>
                </div>
            </div>
            <div class="w-full p-6">
                <div id="Graph" class="w-full p-10 bg-white rounded-lg shadow-lg">
                    <canvas id="chart" ></canvas>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import Product from '@/utils/entities/Product'
import FormatDate from '@/utils/FormatDate'
import InputVue from '../components/elements/Input.vue'
import SelectVue from '../components/elements/Select.vue'
import DatetimefieldVue from '../components/elements/Datetimefield.vue'
import ButtonVue from '../components/elements/Button.vue'
import AutocompleteVue from '../components/elements/Autocomplete.vue'
import CheckboxVue from '../components/elements/Checkbox.vue'
import LoadingVue from '../components/elements/Loading.vue'
import WebsocketService from '@/utils/WebsocketService'

import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend, TimeScale } from 'chart.js';

// Register the necessary components
Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend, TimeScale);

export default ({
    name: 'ProductView',
    components: {
        LoadingVue,
        InputVue,
        ButtonVue,
        SelectVue,
        DatetimefieldVue,
        AutocompleteVue,
        CheckboxVue
    },
    data() {
        const currentDate = new Date();
        const oneWeekAgo = new Date(currentDate.getTime()); // Clone the current date
        oneWeekAgo.setDate(currentDate.getDate() - 7);

        const twoWeeksAgo = new Date(currentDate.getTime()); // Clone the current date
        twoWeeksAgo.setDate(currentDate.getDate() - 14);
        return {
            chartData: null,
            
            products: [],

            loaded: false,
            product: null,

            productBrandState: 'default',
            productBrandMessage: null,

            productReferenceState: 'default',
            productReferenceMessage: null,

            productWeightState: 'default',
            productWeightMessage: null,

            productNetWeightState: 'default',
            productNetWeightMessage: null,
            brandAndRef: null,

            minProductState: 'default',
            minProductMessage: null,

            isSensitiveState: 'default',
            isSensitiveMessage: null,
            
            expiringOffsetState: 'default',
            expiringOffsetMessage: null,

            sections: [],

            bodyVisibility: {
                ProductInfoBody: false,
                RefDatasetBody: false,
                CompDatasetBody: false,
            },
            alreadySubscribed: false,
            filtersReference: {
                barCode: this.$route.params.id,
                itemsPerPage: 1000,
                dateEnd: currentDate,
                dateStart: oneWeekAgo,
            },
            filtersComparaison: {
                barCode: this.$route.params.id,
                itemsPerPage: 1000,
                dateEnd: oneWeekAgo,
                dateStart: twoWeeksAgo,
            },
            chartCanva: null,
            chart: null,
            chartOptions: {
                scales : {
                    x: {
                        type: 'time',
                        time: {
                            displayFormats: {
                                hour: 'd/M HH\'h\''
                            },
                            unit: 'hour',
                        },
                        title: {
                            display: true,
                            text: 'Date et Heure',
                        },
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Event Value',
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            title: (tooltipItems) => {
                                const date = tooltipItems[0].parsed.x;
                                return `Date: ${new Date(date).toLocaleString()}`;
                            },
                        }
                    }
                }
            },
        }
    },
    watch:{
        filtersReference: {
            deep: true,
            handler: async function(newVal) {
                await this.product.getEvents("Reference", newVal);
                this.chart.destroy();
                this.chart = new Chart(this.chartCanva, {
                    type: 'line', // or 'scatter', 'bar', etc.
                    data: this.product.events,
                    options: this.chartOptions,
                });
            },
        },
        filtersComparaison: {
            deep: true,
            handler: async function(newVal) {
                await this.product.getEvents("Comparaison", newVal);
                this.chart.destroy();
                this.chart = new Chart(this.chartCanva, {
                    type: 'line', // or 'scatter', 'bar', etc.
                    data: this.product.events,
                    options: this.chartOptions,
                });
            },
        },
    },

    async mounted() {
        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        this.setupParameters();
        this.brandAndRef = this.$route.params.id
        this.product = new Product({ barCode: this.$route.params.id})

        await this.product.retrieveEntity().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                this.product.initializeWebSocketListener();
            });
        })
        await this.product.getEvents("Reference",this.filtersReference)
        await this.product.getEvents("Comparaison",this.filtersComparaison)
        this.chartData = true
       
        this.chartCanva = document.getElementById('chart').getContext('2d');
        this.chart = new Chart(this.chartCanva, {
            type: 'line', // or 'scatter', 'bar', etc.
            data: this.product.events,
            options: this.chartOptions,
        });
        this.loaded = true
    },

    beforeUnmount() {
        if (this.chart) {
            this.chart.destroy(); // Clean up when the component unmounts
        }
        if (this.product && this.product.barCode) {
            this.product.unsubscribeWebSocket()
        }
    },
    computed: {
        isMobile() {
            return this.$device.isMobile;
        },
        isPortrait() {
            return this.$device.isPortrait;
        },
    },
    methods:{
        updateDateStart(newDate) {
            this.$set(this.filtersReference, 'dateStart', newDate); // Vue.set for reactivity
        },
        async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
        async setupParameters(){
            await this.$axios
            .get(process.env.VUE_APP_API_BASE_PATH+"/settings/section_names",{withCredentials:true})
            .then((response) => {
                this.sections = response.data.value[0].value.map((element) => {
                    return {
                        value: element.section,
                        name: element.section,
                    }
                })
            })
        },
        async saveProduct(){
            let temoin = true;
            if(!this.product.brand){
                this.productBrandState = 'danger'
                this.productBrandMessage = 'Champ Requis'
                temoin = false;
            }
            if(!this.product.reference){
                this.productReferenceState = 'danger'
                this.productReferenceMessage = 'Champ Requis'
                temoin = false;
            }
            if(!this.product.weight){
                this.productWeightState = 'danger'
                this.productWeightMessage = 'Champ Requis'
                temoin = false;
            }
            
            if(temoin){
                await this.product.updateProduct()
            }
        },

        async saveFile(a) {
            let baseUrl = process.env.VUE_APP_API_BASE_PATH + ''
            baseUrl = baseUrl.substring(0, baseUrl.length - 4)
            let formData = new FormData();
            formData.append('file', a.target.files[0]);
            await this.$axios
                .post(process.env.VUE_APP_API_BASE_PATH + '/media_objects',
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                .then(response => {
                    if (response.status == 201) {
                        this.product.imageUrl = baseUrl + response.data.contentUrl
                    }
                })
        },

        async getProducts() {
            await this.$axios
                .get(process.env.VUE_APP_API_BASE_PATH + '/products?simplesearch=' + this.brandAndRef,
                    {
                        withCredentials: true
                    })
                .then(response => {
                    this.products = response.data['hydra:member']
                    this.products.forEach((element, index) => {
                        this.products[index].description = element.brand + ', ' + element.reference
                    })

                })
        },

        setProduct(event) {
            this.brandAndRef = event.description
            this.products = []
        },

        FormatDate(date) {
            return FormatDate(date)
        },

        toggleBody(bodyId) {
            this.bodyVisibility[bodyId] = !this.bodyVisibility[bodyId];
        },

        redirectToLastPage(){
            window.location.replace(window.localStorage.getItem('lastPath'));
        }

    },
})
</script>