import { createApp } from 'vue'
import Router from './router'
import App from './App.vue'
import './index.css'
import axios from 'axios'
import { createPinia } from 'pinia'
import devicePlugin from './plugins/devicePlugin';

const pinia = createPinia()
const app = createApp(App)
app.use(Router)
app.use(pinia)
app.use(devicePlugin)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$global = app.config.globalProperties

app.mount('#app')
